<template>
    <div class="container-fluid">
        <Head></Head>
        <div class="row">


            <div class="col-lg-12" style="top: -180px;overflow: hidden;">
                <div class="mask"></div>
                <div class="container_safet01">
                    <div class="safter01_world">
                        <h4 class="safter01">100% PROTECTION. 100%</h4>
                        <h4 class="safter01">SECURE.</h4>
                        <p class="safter01">Data Security and Destruction</p>
                    </div>
                </div>
                <div class="container_safet02">
                    <div class="safter02_world">
                        <h1 class="safter02">DATA SECURITY AND DESTRUCTION</h1>

                        <p class="safter02">CINO Recycling is a responsible IT Asset Disposition leader with world-class expertise and
                            tools to handle 100% secure Data Security, Destruction and Sanitization for all types of
                            data storage devices.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-7"
                 style="position: relative;left: 50%;transform: translate(-50%);margin-top: -105px;">
                <img alt=""
                     src="../../../assets/image/safter/3.webp">
            </div>


            <div class="col-lg-7"
                 style="position: relative;left: 50%;transform: translate(-50%);margin-top: 25px;background-color: #f1f1f1">
                <div class="safter_middle_title">
                    <h2 class="safter03">Hard Drive Shredding</h2>
                </div>
                <div class="safter_middle_container">
                    <p class="safter03">We provides mobile, quiet, speedy, and secure shredding services to organizations hard drives,
                        cell phones, backup tapes, DLT, LTO, thumb drives and other data storage devices. Performing
                        these services at the client’s location enables their personnel to witness the process take
                        place, thus maintaining the highest level of security and eliminating any chain of custody
                        dilemmas.</p>

                    <p class="safter03">Our onsite services can shred thousands of pounds of metal and plastic per hour. The CINO
                        Recycling's hard drive shredding process has been independently verified and audited based on
                        the industry’s best practices.</p>
                    <a href="javascript:;" @click="gotoMoreOther" class="safter03">More...</a>
                </div>
            </div>

            <div class="col-lg-7"
                 style="position: relative;left: 50%;transform: translate(-50%);margin-top: 45px">
                <img alt=""
                     src="../../../assets/image/safter/4.webp">
            </div>


            <div class="col-lg-7"
                 style="position: relative;left: 50%;transform: translate(-50%);margin-top: 25px;background-color: #f1f1f1">
                <div class="safter_middle_title">
                    <h2 class="safter04">Degaussing</h2>
                </div>
                <div class="safter_middle_container">
                    <p  class="safter04">Degaussing is the process of totally erasing data by reducing or eliminating an unwanted magnetic
                        field (information) stored on tape and disk media. A degausser is a machine that changes the
                        magnetic domain (where the data is stored) of magnetic data storage devices.When the degausser
                        is applied to magnetic domains the information is scrambled into random patterns, making the
                        data stored in the magnetic domain unreadable. The correct use of a the appropriate degausser
                        will ensure that information is no longer</p>
                    <p  class="safter04">retrievable.</p>
                    <p  class="safter04">While degaussers were traditionally used for hard drive destruction, we can now achieve more.
                        CINO Recycling is able to offer a complete, certified data destruction of high-coercivity tape
                        such as DLT, SDLT, 3480’s and AIT.</p>
                    <a href="javascript:;"  @click="gotoMoreOther"   class="safter04">More...</a>
                </div>
            </div>


            <div class="col-lg-7"
                 style="position: relative;left: 50%;transform: translate(-50%);margin-top: 45px">
                <img alt=""
                     src="../../../assets/image/safter/5.webp">
            </div>


            <div class="col-lg-7"
                 style="position: relative;left: 50%;transform: translate(-50%);margin-top: 25px;background-color: #f1f1f1">
                <div class="safter_middle_title">
                    <h2  class="safter05">Microshredding</h2>
                </div>
                <div class="safter_middle_container">
                    <p class="safter05">One of the many things that sets CINO Recycling apart from the competition is our latest
                        technology in shredding. Shredding is part of any secure computer disposal, but larger and older
                        equipment may not be getting the job done.</p>
                    <p  class="safter05">Did you know that Solid State Drives (SSDs), flash drives, smart phones SIM and memory cards, and
                        micro SD cards will literally fall through the cracks of most data destruction and recycling
                        processes? That’s why we’ve added a “microshredding” option that goes beyond traditional
                        shredding and pulverizes devices into 2mm e-crumbs.</p>
                    <p class="safter05">It allows us to ensure total hard drive destruction. With the prevalence of BYOD platforms
                        popping up all over Alabama, we know that you need extra precautions to make sure your data
                        destruction covers every aspect. When you change gear or employees, microshredding is a must to
                        ensure your e-waste is properly processed so it can be recycled.</p>
                    <a class="safter05"  @click="gotoMoreOther"  href="javascript:;">More...</a>
                </div>
            </div>


            <div class="col-lg-12" style="text-align: center">
                <h1 class="last_title_safte">Our approach</h1>
            </div>

            <div class="col-lg-12" style="width: 1980px;height: 570px;overflow: hidden">
                <div class="col-lg-6" style="float: left;width: 50%">
                    <div class="safter_last_img">
                        <img alt=""
                             src="../../../assets/image/safter/6.webp"
                             style="float: right;margin-right: 25px">
                    </div>
                    <div class="safter_last_titles">
                        <h3 class="safter06">A physical destruction device is brought to your location. </h3>
                    </div>
                </div>
                <div class="col-lg-6" style="float: left;width: 50%">
                    <div class="safter_last_img">
                        <img alt=""
                             src="../../../assets/image/safter/7.webp"
                             style="margin-left: 15px">
                    </div>
                    <div class="safter_last_titless">
                        <h3 class="safter06">You watch every step with real time destruction analysis</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-12" style="width: 1980px;height: 570px;overflow: hidden">
                <div class="col-lg-6" style="float: left;width: 50%">
                    <div class="safter_last_img">
                        <img alt=""
                             src="../../../assets/image/safter/8.webp"
                             style="float: right;margin-right: 25px">
                    </div>
                    <div class="safter_last_titles">
                        <h3 class="safter07">A destruction report with serial #'s is provided on site. </h3>
                    </div>
                </div>
                <div class="col-lg-6" style="float: left;width: 50%">
                    <div class="safter_last_img">
                        <img alt=""
                             src="../../../assets/image/safter/9.webp"
                             style="margin-left: 15px">
                    </div>
                    <div class="safter_last_titless">
                        <h3 class="safter07">You receive a certificate of destruction and sanitation.</h3>
                    </div>
                </div>
            </div>

        </div>
        <Bottom style="margin-left: -25px"></Bottom>
    </div>
</template>

<script>
    import {Bottom, Head} from "@/view/layout/commponents";

    export default {
        name: "SafetyAndDestroy",
         metaInfo: {
        meta: [{                 
            name: '暹诺回收官网,手机回收,笔记本回收,数码产品回收,企业回收',
            content: '上海暹诺环保科技有限公司 (简称: 暹诺环保©)是中国领先的环保解决方案提供商，为国内500多家商业和工业企业客户提供回收服务。我们投资开发电子垃圾解决方案，用以适应不断变化的世界。目前该领域不仅包括处理和回收，还包括确保敏感数据的安全的方案，以帮助我们的客户实现零废物的绿色目标。作为中国领先的电子废弃物管理服务供应商，我们的服务宗旨是在最大限度降低影响的同时，又能最大限度地提高资源价值，为所有利益相关者促进经济和环境的可持续发展。'
        }]
        },
        components: {Bottom, Head},
        methods:{
            gotoMoreOther(){
                this.$router.push('/moreother')
            }
        },
        created() {
            $(function () {
                $('.safter01').animate({
                    opacity: '1',
                    top: '15px'
                }, 1000)


                $(window).scroll(function () {
                    var s = $(window).scrollTop();
                    console.log(s)
                    if (s > 250) {
                        $('.safter01').animate({
                            opacity: '1',
                            top: '15px'
                        }, 1000)
                    }
                    if (s > 450) {
                        $('.safter02').animate({
                            opacity: '1',
                            top: '15px'
                        }, 1000)
                    }
                    if (s > 1050) {
                        $('.safter03').animate({
                            opacity: '1',
                            top: '15px'
                        }, 1000)
                    }
                    if (s > 1450) {
                        $('.safter04').animate({
                            opacity: '1',
                            top: '15px'
                        }, 1000)
                    }
                    if (s > 1850) {
                        $('.safter05').animate({
                            opacity: '1',
                            top: '15px'
                        }, 1000)
                    }
                    if (s > 2200) {
                        $('.safter06').animate({
                            opacity: '1',
                            top: '15px'
                        }, 1000)
                    }
                    if (s > 2600) {
                        $('.safter07').animate({
                            opacity: '1',
                            top: '15px'
                        }, 1000)
                    }


                });


            })
        }
    }



    $(function () {
        $('.safter01').animate({
            opacity: '1',
            top: '15px'
        }, 1000)


        $(window).scroll(function () {
            var s = $(window).scrollTop();
            console.log(s)
            if (s > 250) {
                $('.safter01').animate({
                    opacity: '1',
                    top: '15px'
                }, 1000)
            }
            if (s > 450) {
                $('.safter02').animate({
                    opacity: '1',
                    top: '15px'
                }, 1000)
            }
            if (s > 1050) {
                $('.safter03').animate({
                    opacity: '1',
                    top: '15px'
                }, 1000)
            }
            if (s > 1450) {
                $('.safter04').animate({
                    opacity: '1',
                    top: '15px'
                }, 1000)
            }
            if (s > 1850) {
                $('.safter05').animate({
                    opacity: '1',
                    top: '15px'
                }, 1000)
            }
            if (s > 2200) {
                $('.safter06').animate({
                    opacity: '1',
                    top: '15px'
                }, 1000)
            }
            if (s > 2600) {
                $('.safter07').animate({
                    opacity: '1',
                    top: '15px'
                }, 1000)
            }


        });


    })



</script>

<style scoped>
    .safter01{
        opacity: 0;
    }
    .safter02{
        opacity: 0;
    }
    .safter03{
        opacity: 0;
        position: relative;
    }
    .safter04{
        opacity: 0;
        position: relative;
    }
    .safter05{
        opacity: 0;
        position: relative;
    }
    .safter06{
        opacity: 0;
        position: relative;
    }
    .safter07{
        opacity: 0;
        position: relative;
    }


    .col-lg-12 .mask {
        position: absolute;
        height: 1040px;

        background-color: black;

        opacity: .2;
        z-index: 1 ;
    }

    .col-lg-12 .container_safet01 {
        height: 520px;
        background: url("../../../assets/image/safter/1.webp") no-repeat;
        background-size: 100% 100%;
        margin-left: -15px;

    }

    .col-lg-12 .container_safet02 {
        height: 520px;

        background: url("../../../assets/image/safter/2.webp") no-repeat;
        background-size: 100% 100%;
        margin-left: -15px;
    }

    .container_safet01 .safter01_world {
        position: relative;
        height: 300px;
        width: 600px;
        left: 45%;
        top: 40%;
        transform: translate(-50%);
        z-index: 9;
    }

    .safter01_world h4 {
        color: white;
        font-size: 35px;
        font-weight: 800;
    }

    .safter01_world p {
        color: white;
        margin-top: 25px;
        font-weight: 800;
        font-size: 20px;
    }

    .container_safet02 .safter02_world {
        position: relative;
        height: 300px;
        width: 60%;
        left: 50%;
        top: 30%;
        z-index: 9;
        transform: translate(-50%);
        text-align: center;
    }

    .safter02_world h1 {
        color: white;
        font-weight: 800;
        font-size: 38px;
    }

    .safter02_world p {
        color: white;
        font-weight: 800;
        margin-top: 45px;
    }

    .col-lg-7 img {
        height: 100%;
        width: 103.5%;
        margin-left: -15px;

    }

    .col-lg-7 .safter_middle_title {
        text-align: center;
        height: 50px;
        width: 100%;
        margin-top: 15px;

    }

    .safter_middle_title h2 {
        color: black;
        font-weight: 700;
        font-size: 25px;
    }

    .col-lg-7 .safter_middle_container {
        width: 90%;
        text-align: center;
        position: relative;
        left: 50%;
        transform: translate(-50%);
        margin-top: 20px;
    }

    .safter_middle_container p {
        color: black;
        line-height: 1.875em;
        text-align: center;
        font-size: 15px;
    }

    .safter_middle_container a {
        margin-top: 75px;
        margin-bottom: 75px;
        display: inline-block;
    }

    .col-lg-12 .last_title_safte {
        margin-top: 75px;
        margin-bottom: 75px;
        color: black;
        font-weight: 800
    }

    .col-lg-12 .safter_last_img {
        height: 300px;
        width: 100%;


    }

    .col-lg-6 .safter_last_titles {
        height: 200px;
        width: 68%;
        position: relative;
        left: 35%;
        text-align: center;
        margin-top: 70px;

    }

    .safter_last_titles h3 {
        color: black;
        font-size: 22px;
        line-height: 40px;
        font-weight: 800;
    }

    .col-lg-6 .safter_last_titless {
        height: 200px;
        width: 55%;
        text-align: center;
        margin-top: 70px;

        margin-left: 55px;
    }

    .safter_last_titless h3 {
        color: black;
        font-size: 22px;
        line-height: 40px;
        font-weight: 800;
    }


</style>